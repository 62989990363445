'use client';

import React, { useState } from 'react';

import { Button } from './ui/button';

function CounterButton() {
  const [count, setCount] = useState(0);

  const handleClick = () => {
    setCount(count + 1);
  };

  return (
    <Button
      onClick={handleClick}
      aria-label={`Current count is ${count}`}
      className="p-6 bg-blue-500 text-white rounded hover:bg-blue-600"
    >
      {count}
    </Button>
  );
}

export default CounterButton;
